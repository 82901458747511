<template>
	<layout-default>
		<div>
			<div>
				Our Company: <b>{{ state.ourCompany }}</b
				><br />
				Beginning Date: <b>{{ state.indexBeginningDate }}</b
				><br />
				Ending Date: <b>{{ state.indexEndingDate }}</b
				><br />
			</div>
			<br />
			<div>
				<b-button
					style="width: 300px"
					:disabled="disableSaveButton"
					class="is-primary form-button"
					type="submit"
					@click="onSubmit"
					>Save Inventory Values
				</b-button>
				<div
					class="text-red text-bold"
					style="margin-top: -10px; margin-bottom: 30px"
				>
					.... Save inventory credit and debit values from dispatches & purchase
					invoices
				</div>
			</div>
			<div>
				<b-button
					style="width: 300px"
					:disabled="disableReportButton"
					class="is-success form-button"
					type="submit"
					@click="getDetailedReport"
					>Get Detailed Inventory Report
				</b-button>
			</div>
		</div>
	</layout-default>
</template>

<script>
// Cleave
// eslint-disable-next-line no-unused-vars
import { computed, reactive, ref, onMounted } from '@vue/composition-api'
// import Store from '@/store'
import { useQuery, useMutation } from '@vue/apollo-composable'
import GetStockDispatchedValuesQuery from '@/_srcv2/graphql/stock/queries/getStockDispatchedValues.query.gql'
import GetStockPurchasedValuesQuery from '@/_srcv2/graphql/stock/queries/getStockPurchasedValues.query.gql'
import SetInventoryStockCreditMutation from '@/_srcv2/graphql/stock/mutations/setInventoryStockCredit.mutation.gql'
import SetInventoryStockDebitMutation from '@/_srcv2/graphql/stock/mutations/setInventoryStockDebit.mutation copy.gql'
import SetInventoryCreditDebitZeroMutation from '@/_srcv2/graphql/stock/mutations/setInventoryCreditDebitZero.mutation.gql'
import useInventoryState from '@/_srcv2/views/pages/startup/components/set-inventory-values/useInventoryState'
import Router from '@/router'

export default {
	name: 'GetInventoryValues',
	setup() {
		const disableReportButton = ref(true)
		const disableSaveButton = ref(false)
		const { state } = useInventoryState()
		const userCurrentCompany = computed(() => state.ourCompany)
		console.log('userCurrentCompany', userCurrentCompany.value)
		// ! Reset stock inventory credit values
		const { mutate: resetInventory, onDone: resetDone } = useMutation(
			SetInventoryCreditDebitZeroMutation,
			() => ({
				variables: {
					company_id: userCurrentCompany.value,
				},
			}),
		)
		resetDone(() => alert('Credit Debit values are reseted'))

		// *------------------------------------------------------
		const resetPromise = () => {
			return new Promise((resolve, reject) => {
				let condition = true
				if (condition) {
					resetInventory()
					resolve('done')
				} else {
					const error = new Error('Credit Debit values can not be reset')
					reject(error)
				}
			}).catch((err) => console.log('Error: ', err))
		}
		onMounted(() => {
			resetInventory()
		})
		//  ----------------------------------------------------------------
		const { mutate: setStockInventoryCredit } = useMutation(
			SetInventoryStockCreditMutation,
		)
		const { mutate: setStockInventoryDebit } = useMutation(
			SetInventoryStockDebitMutation,
		)
		// * -----------------------------------------------------------------
		// ! Get the dispatched & purchased data from database
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { refetch: refetchDispatchedAmountValue } = useQuery(
			GetStockDispatchedValuesQuery,
			() => ({
				company_id: state.ourCompany,
				dispatch_date_beginning: state.indexBeginningDate,
				dispatch_date_ending: state.indexEndingDate,
			}),
			options,
		)
		// ------------------------------------------------------------------
		const { refetch: refetchPurchasedAmountValue } = useQuery(
			GetStockPurchasedValuesQuery,
			() => ({
				our_company: state.ourCompany,
				purchase_date_beginning: state.indexBeginningDate,
				purchase_date_ending: state.indexEndingDate,
			}),
			options,
		)
		// * -----------------------------------------------------------------
		// ! Add Inventory Values
		// * Define group by function to use later
		function groupBy(list, keyGetter) {
			const map = new Map()
			list.forEach((item) => {
				const key = keyGetter(item)
				const collection = map.get(key)
				if (!collection) {
					map.set(key, [item])
				} else {
					collection.push(item)
				}
			})
			return map
		}
		// * -----------------------------------------------------------------
		// ! onSubmit
		let stockItemIdList = []
		let groupedData
		const onSubmit = () => {
			console.log('submit is fired')
			resetPromise()
				.then(() => refetchDispatchedAmountValue())
				.then((result) => result.data.goods_transactions)
				.then((list) => {
					return list.map((item) => {
						return {
							stock_id: item.stock_id,
							dispatch_amount: item.dispatch_amount,
							line_price_total_credit: item.line_price_total_credit,
						}
					})
				})
				.then((mappedList) => {
					console.log('mappedList', mappedList)
					// const result = mappedList.filter((item) => item.stock_id === null)
					// console.log('----result', result)
					const grouped = groupBy(mappedList, (item) => item.stock_id)
					groupedData = grouped
					return grouped
				})
				.then((grouped) => {
					// console.log('grouped', grouped)
					for (let [key] of grouped) {
						stockItemIdList.push(key)
					}
				})
				.then(() => {
					console.log('stockItemIdList', stockItemIdList)
					stockItemIdList.forEach((stockId) => {
						let inventoryCredit = groupedData
							.get(stockId)
							.reduce((inventory_credit, item) => {
								inventory_credit += item.dispatch_amount
								return inventory_credit
							}, 0)
						let inventoryRevenue = groupedData
							.get(stockId)
							.reduce((inventory_revenue, item) => {
								inventory_revenue += item.line_price_total_credit
								return inventory_revenue
							}, 0)
						let obj = {
							company_id: state.ourCompany,
							stock_id: stockId,
							inventory_credit: inventoryCredit,
							inventory_revenue: inventoryRevenue,
						}
						console.log('------------obj', obj)
						setStockInventoryCredit(obj)
					})
				})
				.then(() => {
					groupedData.clear()
					stockItemIdList = []
					disableReportButton.value = false
				})
				.then(() => refetchPurchasedAmountValue())
				.then((result) => result.data.goods_transactions)
				.then((list) => {
					return list.map((item) => {
						return {
							stock_id: item.stock_id,
							amount_debit: item.amount_debit,
							line_price_total_debit: item.line_price_total_debit,
						}
					})
				})
				.then((mappedList) => {
					console.log('mappedList', mappedList)
					// const result = mappedList.filter((item) => item.stock_id === null)
					// console.log('----result', result)
					const grouped = groupBy(mappedList, (item) => item.stock_id)
					groupedData = grouped
					return grouped
				})
				.then((grouped) => {
					// console.log('grouped', grouped)
					for (let [key] of grouped) {
						stockItemIdList.push(key)
					}
				})
				.then(() => {
					console.log('stockItemIdList', stockItemIdList)
					stockItemIdList.forEach((stockId) => {
						let inventoryDebit = groupedData
							.get(stockId)
							.reduce((inventory_debit, item) => {
								inventory_debit += item.amount_debit
								return inventory_debit
							}, 0)
						let inventoryCost = groupedData
							.get(stockId)
							.reduce((inventory_cost, item) => {
								inventory_cost += item.line_price_total_debit
								return inventory_cost
							}, 0)
						let obj = {
							company_id: state.ourCompany,
							stock_id: stockId,
							inventory_debit: inventoryDebit,
							inventory_cost: inventoryCost,
						}
						console.log('------------obj', obj)
						setStockInventoryDebit(obj)
					})
				})
				.then(() => {
					groupedData.clear()
					stockItemIdList = []
					disableReportButton.value = false
				})
				.catch((error) => console.log(error.message))
		}
		const getDetailedReport = () => {
			Router.push({ name: 'InventoryReportDetailed' })
		}
		return {
			disableReportButton,
			disableSaveButton,
			onSubmit,
			state,
			getDetailedReport,
		}
	},
}
</script>

<style scoped>
.form-button {
	margin-bottom: 15px;
}
</style>
